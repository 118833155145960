(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import axios from 'axios';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
const mainRoute = 'users';

const registerSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload
});

const registerError = payload => ({
  type: REGISTER_ERROR,
  payload
});

export const registerRequest = data => dispatch => {
  console.log('data', data.body);
  dispatch({
    type: REGISTER_REQUEST
  });
  axios.post(mainRoute, data).then(({
    data
  }) => dispatch(registerSuccess(data.body))).catch(error => {
    dispatch(registerError(error)); // return Promise.reject(error);
  });
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(REGISTER_REQUEST, "REGISTER_REQUEST", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(REGISTER_SUCCESS, "REGISTER_SUCCESS", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(REGISTER_ERROR, "REGISTER_ERROR", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(mainRoute, "mainRoute", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(registerSuccess, "registerSuccess", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(registerError, "registerError", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
  reactHotLoader.register(registerRequest, "registerRequest", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/registerActions.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();