(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { combineReducers } from 'redux';
import registerReducers from './registerReducers';
import loginReducers from './loginReducers';
import userReducers from './userReducers';
const rootReducer = combineReducers({
  register: registerReducers,
  login: loginReducers,
  user: userReducers
});
const _default = rootReducer;
export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(rootReducer, "rootReducer", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/index.js");
  reactHotLoader.register(_default, "default", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();