(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from '../actions/loginActions';
const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  user: {}
};

const loginReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state,
        loading: true
      };

    case LOGIN_SUCCESS:
      return { ...state,
        loading: false,
        hasError: false,
        user: action.payload
      };

    case LOGIN_ERROR:
      return { ...state,
        loading: false,
        hasError: true,
        error: action.payload
      };

    default:
      return state;
  }
};

const _default = loginReducers;
export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(INITIAL_STATE, "INITIAL_STATE", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/loginReducers.js");
  reactHotLoader.register(loginReducers, "loginReducers", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/loginReducers.js");
  reactHotLoader.register(_default, "default", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/loginReducers.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();