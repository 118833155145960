(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import axios from 'axios';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
const mainRoute = 'users';

const userSuccess = payload => ({
  type: USER_SUCCESS,
  payload
});

const userError = payload => ({
  type: USER_ERROR,
  payload
});

export const changePasswordRequest = data => dispatch => {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST
  });
  axios.post(`${mainRoute}/change-password`, data).then(({
    data
  }) => dispatch(userSuccess(data.body))).catch(error => {
    dispatch(userError(error)); // return Promise.reject(error);
  });
};
export const resetPasswordRequest = data => dispatch => {
  dispatch({
    type: CHANGE_PASSWORD_REQUEST
  });
  axios.post(`${mainRoute}/reset-password`, data).then(({
    data
  }) => dispatch(userSuccess(data.body))).catch(error => {
    dispatch(userError(error)); // return Promise.reject(error);
  });
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(CHANGE_PASSWORD_REQUEST, "CHANGE_PASSWORD_REQUEST", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(USER_SUCCESS, "USER_SUCCESS", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(USER_ERROR, "USER_ERROR", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(mainRoute, "mainRoute", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(userSuccess, "userSuccess", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(userError, "userError", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(changePasswordRequest, "changePasswordRequest", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
  reactHotLoader.register(resetPasswordRequest, "resetPasswordRequest", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/userActions.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();