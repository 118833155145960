(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_ERROR } from '../actions/registerActions';
const INITIAL_STATE = {
  loading: false,
  hasError: false,
  error: null,
  data: []
};

const registerReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return { ...state,
        loading: true
      };

    case REGISTER_SUCCESS:
      return { ...state,
        loading: false,
        hasError: false,
        data: action.payload
      };

    case REGISTER_ERROR:
      return { ...state,
        loading: false,
        hasError: true,
        error: action.payload
      };

    default:
      return state;
  }
};

const _default = registerReducers;
export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(INITIAL_STATE, "INITIAL_STATE", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/registerReducers.js");
  reactHotLoader.register(registerReducers, "registerReducers", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/registerReducers.js");
  reactHotLoader.register(_default, "default", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/reducers/registerReducers.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();