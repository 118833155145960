(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

require('dotenv').config();

const config = {
  apiUrl: process.env.API_URL,
  //DB
  dbUser: process.env.DB_USER,
  dbPassword: process.env.DB_PASSWORD,
  dbHost: process.env.DB_HOST,
  dbPort: process.env.DB_PORT,
  dbName: process.env.DB_NAME,
  dbAppUser: process.env.DB_APP_USER,
  dbAppPassword: process.env.DB_APP_PASSWORD,
  dbAppHost: process.env.DB_APP_HOST,
  dbAppName: process.env.DB_APP_NAME,
  dbAppPort: process.env.DB_APP_PORT,
  //JWT
  authJwtSecret: process.env.AUTH_JWT_SECRET || 'AUTH_JWT_SECRET',
  //GMAIL
  gmailAddress: process.env.GMAIL_ADDRESS || 'noreply@it49multimedia.com',
  gmailOauthClientId: process.env.GMAIL_OAUTH_CLIENT_ID || '218631959575-trgq72sovv8ptednb91ao0k41bfmppki.apps.googleusercontent.com',
  gmailOauthProjectId: process.env.GMAIL_OAUTH_PROJECT_ID || 'savvy-summit-281300',
  gmailOauthClientSecret: process.env.GMAIL_OAUTH_CLIENT_SECRET || 'wMa7JzZDh4kOlQm6jQRKOoiT',
  gmailOauthRedirectUrl: process.env.GMAIL_OAUTH_REDIRECT_URL || 'http://localhost:3000/api/smtp',
  gmailOauthRefreshToken: process.env.GMAIL_OAUTH_REFRESH_TOKEN || '1//05911abGIOcp2CgYIARAAGAUSNwF-L9IrPmMKyMZ3Y0niJcnRsrNS37exqdKxtlDIEczSkoDAXhvyNP0e4uYjSTO-SioZSKLWbZk',
  gmailOauthAccessToken: process.env.GMAIL_OAUTH_ACCESS_TOKEN || 'ya29.a0AfH6SMBFDqikwXikdhCB-pntSe68FOTQIzgNHv-reJ7D6Ag2Nvn-uD0fBkQCRLO4NPAkaZqUxx3lhX5I07WD1EsvsJXWu03il0r1dcZp2UgYjbsVvQD7GMhSRNEqpbKZ4ntYkkT5WtAhyEqXLCCD5QAfeeRfSipbKNg',
  gmailOauthTokenExpire: process.env.GMAIL_OAUTH_TOKEN_EXPIRE || '1595437124356',
  imagekitPublicKey: process.env.IMAGEKIT_PUBLIC_KEY || 'public_cNbQ0ntxOLVqyeCVVuwwQ9n0nw8=',
  imagekitPrivateKey: process.env.IMAGEKIT_PRIVATE_KEY || 'private_FBcNvYB58mBNkGjC5UI67NQ+Oy0=',
  imagekitUrlEndpoint: process.env.IMAGEKIT_URL_ENDPOINT || 'https://ik.imagekit.io/an1ince54c3/'
};
module.exports = {
  config
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(config, "config", "/var/www/html/websites/thedistrictusc-com/src/server/config/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();