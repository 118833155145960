(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import axios from 'axios';
import Cookie from 'js-cookie';
import { removeData } from '../../utils/auth';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
const mainRoute = 'users';

const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});

const loginError = payload => ({
  type: LOGIN_ERROR,
  payload
});

export const loginRequest = ({
  username,
  password
}, redirecUrl = '/shippings') => dispatch => {
  dispatch({
    type: LOGIN_REQUEST
  });
  axios({
    url: `${mainRoute}/sign-in`,
    method: 'post',
    auth: {
      username,
      password
    }
  }).then(({
    data
  }) => {
    const {
      user
    } = data.body; //to set a cookie

    Cookie.set('token', user.token);
    Cookie.set('user', user);
    dispatch(loginSuccess(user));
  }).then(() => {
    window.location.href = redirecUrl;
  }).catch(err => {
    let error = 'Error en la solicitud';

    if (err && err.response && err.response.status === 401) {
      error = 'Usuario y/o contraseña incorrectos';
    }

    dispatch(loginError(error));
  });
};
export const logoutRequest = (redirect = true, redirecUrl = '/login') => dispatch => {
  dispatch({
    type: LOGOUT_REQUEST
  });

  try {
    removeData();
    dispatch(loginSuccess({}));
    if (redirect) window.location.href = redirecUrl;
  } catch (error) {
    dispatch(loginError(error));
  }
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(LOGIN_REQUEST, "LOGIN_REQUEST", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(LOGOUT_REQUEST, "LOGOUT_REQUEST", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(LOGIN_SUCCESS, "LOGIN_SUCCESS", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(LOGIN_ERROR, "LOGIN_ERROR", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(mainRoute, "mainRoute", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(loginSuccess, "loginSuccess", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(loginError, "loginError", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(loginRequest, "loginRequest", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
  reactHotLoader.register(logoutRequest, "logoutRequest", "/var/www/html/websites/thedistrictusc-com/src/frontend/redux/actions/loginActions.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();