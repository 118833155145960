(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import cookie from 'js-cookie';
export const token = cookie.get('token') ? cookie.get('token') : null;
export const user = cookie.get('user') ? JSON.parse(cookie.get('user')) : null;
export const removeData = () => {
  cookie.remove('token');
  cookie.remove('user');
};
export const isInRole = roleName => {
  if (!user || !user.roles || user.roles.length === 0) {
    return false;
  }

  return user.roles.includes(roleName);
};
export const isInRoles = (roles = []) => {
  let exist = false;
  roles.forEach(roleName => {
    exist = isInRole(roleName);
  });
  return exist;
};
export const userIsInRole = (user, roleName) => {
  if (!user || !user.roles || user.roles.length === 0) {
    return false;
  }

  return user.roles.includes(roleName);
};
export const userIsInRoles = (user, roles = []) => {
  let exist = false;
  roles.forEach(roleName => {
    exist = userIsInRole(user, roleName);
  });
  return exist;
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(token, "token", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(user, "user", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(removeData, "removeData", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(isInRole, "isInRole", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(isInRoles, "isInRoles", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(userIsInRole, "userIsInRole", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
  reactHotLoader.register(userIsInRoles, "userIsInRoles", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/auth.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();